import styled from "styled-components";

export default styled.select`
  border: none;
  padding: 11px;
  outline: none;
  background: transparent;
  color: inherit;
  margin: 0;
  border: 1px solid rgba(0,0,0,0.33);
  border-radius: 4px;
  width: 100%;
  outline: none;
  height: 44px;
  align-self: flex-end;
  background-color: white;
`
