import { TextInput } from 'grommet'
import React from 'react'
import Select from '../../../UI/Select'
import TaskChallengesState from '../../index.state'
import Layout from './Layout'

const GAMES = {
  'word-hop': { title: 'Word Hop' },
  'word-climb': { title: 'Word Climb' },
  'word-hunt': { title: 'Word Hunt' },
  'jumble-words': { title: 'Jumbled Words' },
  'torch-type': { title: 'Torch Type' },
  'word-wings': { title: 'Word Wings' },
  'word-pairs': { title: 'Word Pairs' },
  'word-woods': { title: 'Word Woods' },
  'word-wrecker': { title: 'Word Wrecker' },
}

const CreateGameTaskChallenge = (props) => {
  const { createGameTaskChallenge } = TaskChallengesState.useContainer()
  return <Layout
    title="Game Challenge"
    dismiss={props.dismiss}
    onCreate={createGameTaskChallenge}>
    <div>
      <div>Game ID</div>
      <Select>
        {
          Object.keys(GAMES).map(d => <option key={d}>
            { GAMES[d].title }
          </option>)
        }
      </Select>
    </div>
    <div>
      <div>Minimum score</div>
      <TextInput type='number' />
    </div>
  </Layout>
}

CreateGameTaskChallenge.propTypes = {

}

CreateGameTaskChallenge.defaultProps = {

}

export default CreateGameTaskChallenge
