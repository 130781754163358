import React from 'react'
import TaskChallengesState from '../../index.state'
import Layout from './Layout'

const CreateLessonTaskChallenge = (props) => {
  const { createLessonTaskChallenge } = TaskChallengesState.useContainer()
  return <Layout
    title="Lesson Challenge"
    dismiss={props.dismiss}
    onCreate={createLessonTaskChallenge}>
  </Layout>
}

CreateLessonTaskChallenge.propTypes = {

}

CreateLessonTaskChallenge.defaultProps = {

}

export default CreateLessonTaskChallenge
