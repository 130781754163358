import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../theme'
import Button from '../../../UI/Button'
import Calendar from '../../../UI/Calendar'

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  min-height: 400px;
  min-width: 550px;
  max-height: 600px;
  overflow: auto;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
`

const Title = styled.div`
  font-size: 21px;
  color: ${colors.indigo};
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;
`

const Form = styled.div`
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Dates = styled.div`
  display: flex;
  margin-bottom: 20px;
  > :first-child {
    margin-right: 15px;
  }
  > div > :first-child {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }
`

const Fields = styled.div`
  > div {
    margin-bottom: 20px;
  }
  > div > :first-child {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }
`

const Layout = (props) => {
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())

  return <Overlay onClick={e => {
    props.dismiss()
  }}>
    <Wrapper onClick={e => e.stopPropagation()}>
      <Title>{props.title}</Title>
      <Form>
        <Dates>
          <div>
            <div>From</div>
            <Calendar date={from} onSelect={(d) => setFrom(d)} size='small' bounds={[new Date().toISOString(), "2040-12-31"]} />
          </div>
          <div>
            <div>To</div>
            <Calendar date={to} onSelect={(d) => setTo(d)} size='small' bounds={[new Date().toISOString(), "2040-12-31"]} />
          </div>
        </Dates>
        <Fields>
          { props.children }
        </Fields>
        <Button onClick={(input) => props.onCreate({ from, to, ...input })}>Create</Button>
      </Form>
    </Wrapper>
  </Overlay>
}

Layout.propTypes = {

}

Layout.defaultProps = {

}

export default Layout
