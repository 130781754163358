import { Calendar } from "grommet";
import styled from "styled-components";

export default styled(Calendar)`
  background-color: #fff;
  color: #000;
  padding: 15px;
  width: 100%;
  width: auto;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
`
