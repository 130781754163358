import { TextInput } from 'grommet'
import React from 'react'
import TaskChallengesState from '../../index.state'
import Layout from './Layout'

const CreateCommentTaskChallenge = (props) => {
  const { createCommentTaskChallenge } = TaskChallengesState.useContainer()
  return <Layout
    title='Comment Challenge'
    dismiss={props.dismiss}
    onCreate={createCommentTaskChallenge}>
    <div>
      <div>Number of comments</div>
      <TextInput type='number' />
    </div>
  </Layout>
}

CreateCommentTaskChallenge.propTypes = {

}

CreateCommentTaskChallenge.defaultProps = {

}

export default CreateCommentTaskChallenge
