import { TextInput } from 'grommet'
import React from 'react'
import Select from '../../../UI/Select'
import TaskChallengesState from '../../index.state'
import Layout from './Layout'

const WRITING_TYPES = {
  "explanation" : {
    title: 'Explanation'
  },
  "report": {
    title: 'Report'
  },
  "adventure-story": {
    title: 'Adventure'
  },
  "letter-writing": {
    title: 'Letter'
  },
  "persuasive-writing": {
    title: 'Persuasive'
  },
  "nonfiction": {
    title: 'Non Fiction'
  },
  "fiction": {
    title: 'Fiction'
  },
  "playscript": {
    title: 'Playscript'
  },
  "poem": {
    title: "Poem"
  }
}

const CreateWritingTaskChallenge = (props) => {
  const { createWritingTaskChallenge } = TaskChallengesState.useContainer()
  return <Layout
    title="Writing Challenge"
    dismiss={props.dismiss}
    onCreate={createWritingTaskChallenge}>
    <div>
      <div>Writing type</div>
      <Select>
        {
          Object.keys(WRITING_TYPES).map(d => <option key={d}>
            { WRITING_TYPES[d].title }
          </option>)
        }
      </Select>
    </div>
    <div>
      <div>Min number of words</div>
      <TextInput type='number' />
    </div>
  </Layout>
}

CreateWritingTaskChallenge.propTypes = {

}

CreateWritingTaskChallenge.defaultProps = {

}

export default CreateWritingTaskChallenge
