import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import CreateCommentTaskChallenge from './components/CreateTask/CreateCommentTaskChallenge'
import CreateGameTaskChallenge from './components/CreateTask/CreateGameTaskChallenge'
import CreateLessonTaskChallenge from './components/CreateTask/CreateLessonTaskChallenge'
import CreateWritingTaskChallenge from './components/CreateTask/CreateWritingTaskChallenge'
import TaskChallengesState from './index.state'

const Events = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
`

const Title = styled.div`
  color: ${colors.indigo};
  font-weight: bold;
  font-size: 18px;
  font-family: 'Roboto';
`

const TaskChallenges = (props) => {
  const { loading } = TaskChallengesState.useContainer()
  const [form, setForm] = useState(null)

  if (loading) return <div>Loading...</div>
  return <div>
    <Events>
      <Title>Events</Title>
      { form }
      <div>
        { /* eslint-disable-next-line */ }
        <div onClick={() => setForm(<CreateWritingTaskChallenge dismiss={setForm} />)}>Create Writing Task</div>
        { /* eslint-disable-next-line */ }
        <div onClick={() => setForm(<CreateLessonTaskChallenge dismiss={setForm} />)}>Create Lesson Task</div>
        { /* eslint-disable-next-line */ }
        <div onClick={() => setForm(<CreateCommentTaskChallenge dismiss={setForm} />)}>Create Comment Task</div>
        { /* eslint-disable-next-line */ }
        <div onClick={() => setForm(<CreateGameTaskChallenge dismiss={setForm} />)}>Create Game Task</div>
      </div>
    </Events>
  </div>
}

TaskChallenges.propTypes = {

}

TaskChallenges.defaultProps = {

}

export default props => <TaskChallengesState.Provider>
  <TaskChallenges {...props} />
</TaskChallengesState.Provider> 
