import React from "react"

import TaskChallenges from "../components/TaskChallenges"

const TaskChallengesPage = () => {
  if (typeof window === "undefined") return null

  return <TaskChallenges />
}

export default TaskChallengesPage
