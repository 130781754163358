import { createContainer } from "unstated-next"
import { useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"
import GET_EVENTS from './graphql/getEvents.graphql'
import CREATE_COMMENT_TASK_CHALLENGE from './graphql/createCommentTaskChallenge.graphql'
import CREATE_WRITING_TASK_CHALLENGE from './graphql/createWritingTaskChallenge.graphql'
import CREATE_GAME_TASK_CHALLENGE from './graphql/createGameTaskChallenge.graphql'
import CREATE_LESSON_TASK_CHALLENGE from './graphql/createLessonTaskChallenge.graphql'


const useTaskChallengesState = () => {
  const client = useApolloClient()
  const [events, setEvents] = useState([])
  const [, setLoading] = useState(false)

  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  const fetch = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_EVENTS,
      variables: {},
      fetchPolicy: 'network-only'
    })
    setEvents(data.zooEvents)
    setLoading(false)
  }

  const createTask = async (type, input) => {
    const mutations = {
      'COMMENT': {
        mutation: CREATE_COMMENT_TASK_CHALLENGE,
        field: 'createCommentTaskChallenge'
      },
      'WRITING': {
        mutation: CREATE_WRITING_TASK_CHALLENGE,
        field: 'createWritingTaskChallenge'
      },
      'GAME': {
        mutation: CREATE_GAME_TASK_CHALLENGE,
        field: 'createGameTaskChallenge'
      },
      'LESSON': {
        mutation: CREATE_LESSON_TASK_CHALLENGE,
        field: 'createLessonTaskChallenge'
      }
    }
    const { data } = await client.mutate({
      query: mutations[type],
      variables: { input },
      fetchPolicy: 'network-only'
    })
    setEvents([...events, data[mutations[type].field]])
  }

  const createCommentTask = (input) => {
    createTask('COMMENT', input)
  }

  const createWritingTask = (input) => {
    createTask('WRITING', input)
  }

  const createLessonTask = (input) => {
    createTask('LESSON', input)
  }

  const createGameTask = (input) => {
    createTask('GAME', input)
  }

  return {
    events,
    createCommentTask,
    createWritingTask,
    createLessonTask,
    createGameTask
  }
}

const TaskChallengesState = createContainer(useTaskChallengesState)

export default TaskChallengesState
